import React from 'react'
import styled from 'styled-components'

// Elements
import CustomLink from 'components/shared/CustomLink'

import { motion } from 'framer-motion'

const LinkWrapper = styled(motion.div)``

const StyledCustomLink = styled(CustomLink)`
  font-size: ${(props) => props.theme.font.size['18']};
  color: ${(props) => props.theme.color.text.main};

  @media (max-width: 991px) {
    border: 1px solid ${(props) => props.theme.color.face.main};
    min-height: 100px;
    min-width: 311px;
    border-radius: 0;
  }
  @media (max-width: 575px) {
    font-size: ${(props) => props.theme.font.size['14']};
  }
  @media (max-width: 325px) {
    min-width: 250px;
    max-width: 250px;
  }
`

const StyledImage = styled.img`
  max-height: 40px;
  max-width: 40px;
  min-width: 40px;
`

interface LinksProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Links
  location?: any
}

const Links: React.FC<LinksProps> = ({ fields }) => (
  <section className="mt-2 container">
    <div className="py-lg-5 mx-5">
      <div className="row py-lg-0 py-5">
        {fields?.linkgroup?.map((edge, index: number) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className="col-lg col-md-6 my-3 my-lg-0 d-flex justify-content-center align-items-center"
          >
            <LinkWrapper whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <StyledCustomLink
                to={edge?.link?.url || ''}
                className="px-4 py-3 d-flex align-items-center justify-content-center"
              >
                <div className="d-inline-flex align-items-center w-100 justify-content-between">
                  <StyledImage
                    src={edge?.image?.localFile?.publicURL}
                    className="mr-3"
                    alt="Lofthus"
                  />
                  {edge?.link?.title}
                </div>
              </StyledCustomLink>
            </LinkWrapper>
          </div>
        ))}
      </div>
    </div>
  </section>
)

export default Links
